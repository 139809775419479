import React, { useEffect } from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import { fetchProducts } from "../../../redux/megaSlice"; 
import { useDispatch, useSelector } from 'react-redux';

const SpecialOffers = ({ limit = 2, shuffle = true }) => {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.megaReducer.products); 
  const status = useSelector((state) => state.megaReducer.status);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchProducts());
    }
  }, [status, dispatch]);

  // Shuffle function
  const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  // Limit and shuffle items
  let displayedItems = items.slice(0, limit);
  if (shuffle) {
    displayedItems = shuffleArray(displayedItems);
  }

  return (
    <div className="w-full pb-20">
      <Heading heading="Special Offers" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-4 gap-10">
        {status === 'loading' && <p>Loading products...</p>}
        {status === 'failed' && <p>Failed to load products.</p>}
        {status === 'succeeded' && displayedItems.map((item) => (
          <Product
            key={item._id}
            _id={item._id}
            img={item.images}
            productName={item.name}
            price={item.price}
            color={item.color}
            badge={item.badge}
            des={item.des}
            />
          ))}
      </div>
    </div>
  );
};

export default SpecialOffers;
