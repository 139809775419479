import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Heading from "../../home/Products/Heading";
import Product from "../../home/Products/Product";
import onsale from "../../pageProps/productDetails/onsale.css"
import { fetchProducts } from "../../../redux/megaSlice";

const ProductsOnSale = ({ limit = 2 }) => {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.megaReducer.products);
  const status = useSelector((state) => state.megaReducer.status);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchProducts());
    }
  }, [status, dispatch]);

  return (
    <div className="w-full pb-16 md:pb-20">
      <Heading heading="Products on Sale" />
      <div className="w-full wrap wrap sm:wrap-cols-2 lg:grid gap-8">
        {status === 'loading' && <p>Loading...</p>}
        {status === 'succeeded' && items.slice(0, limit).map((item) => (
          <Product
            key={item._id}
            _id={item._id}
            img={item.images}
            productName={item.name}
            price={item.price}
            disableHover={true}
          />
        ))}
        {status === 'failed' && <p>Error loading products on sale.</p>}
      </div>
    </div>
  );
};

export default ProductsOnSale;