import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../redux/megaSlice";
import { FaStar } from "react-icons/fa"; 

const ProductInfo = ({ productInfo }) => {
  const dispatch = useDispatch();
  const [rating, setRating] = useState(0); 
  const [hover, setHover] = useState(null);

  const handleAddToCart = () => {
    dispatch(
      addToCart({
        _id: productInfo.id,
        name: productInfo.productName,
        quantity: 1,
        image: productInfo.img,
        badge: productInfo.badge,
        price: productInfo.price,
        colors: productInfo.color,
      })
    );
  };

  return (
    <div className="flex flex-col gap-5">
      <h2 className="text-4xl font-semibold">{productInfo.productName}</h2>
      <p className="text-xl font-semibold">{productInfo.price} GHS</p>
      <p className="text-base text-gray-600">{productInfo.des}</p>

      {/* Star Rating System */}
      <p className="text-sm text-gray-700">
        Drop a review to help others know we are the best.
      </p>
      <div className="flex items-center gap-1">
        {[...Array(5)].map((_, index) => {
          const currentRating = index + 1;
          return (
            <FaStar
              key={index}
              className={`cursor-pointer text-2xl ${
                currentRating <= (hover || rating) ? "text-yellow-500" : "text-gray-300"
              }`}
              onClick={() => setRating(currentRating)}
              onMouseEnter={() => setHover(currentRating)}
              onMouseLeave={() => setHover(null)}
            />
          );
        })}
      </div>
      <button
        onClick={handleAddToCart}
        className="w-full py-4 bg-primeColor hover:bg-black transition-all duration-300 text-white text-lg font-semibold"
      >
        Add to Cart
      </button>
      <p className="font-normal text-sm text-gray-600">
        <span className="text-base font-medium">Categories:</span> Home appliances, Construction Appliances, Plumbing Appliances, Hammers | SKU: N/A
      </p>
    </div>
  );
};

export default ProductInfo;
