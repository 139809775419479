import { useLocation } from 'react-router-dom';

export default function ConfirmEmail() {
  const location = useLocation();
  const email = new URLSearchParams(location.search).get('email');

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <div className="d-flex justify-content-center align-items-center vh-100 bg-light m-0">
      <div className="container text-center bg-white p-3 rounded-3 shadow-sm">
        <h1 className="text-dark">Confirm Your Email Address</h1>
        <p className="text-secondary">Please confirm your email address</p>
        <p className="text-secondary">We have sent an email to <strong>{email}</strong></p>
      </div>
    </div>
    </div>
  );
}
