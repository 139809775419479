import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  userInfo: [],
  products: [],
  cartStatus: "idle",
  status: "idle",
  categoryStatus: "idle",
  productsOnSale: "idle",
  error: null,
};

// Fetch user info
export const getUserInfo = createAsyncThunk(
  "megacrafters/getUserInfo",
  async (id) => {
    const response = await fetch(
      `https://admin.clejenterprise.com/api/users?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Referrer-Policy": "no-referrer",
        },
      }
    ); //
    const data = await response.json();
    console.log(data);
    if (data === undefined) {
      return [];
    }

    return data;
  }
);

// Fetch products from the API
export const fetchProducts = createAsyncThunk(
  "megacrafters/fetchProducts",
  async () => {
    const response = await fetch(
      "https://admin.clejenterprise.com/api/products",
      {
        headers: {
          "Content-Type": "application/json",
          "Referrer-Policy": "no-referrer",
        },
      }
    ); //
    const data = await response.json();

    if (data === undefined) {
      return [];
    }

    return data;
  }
);

export const fetchCategories = createAsyncThunk(
  "megacrafters/fetchCategories",
  async () => {
    const response = await fetch(
      "https://admin.clejenterprise.com/api/categories",
      {
        headers: {
          "Content-Type": "application/json",
          "Referrer-Policy": "no-referrer",
        },
      }
    );
    const data = await response.json();
    if (data === undefined) {
      return [];
    }
    return data;
  }
);

export const fetchCart = createAsyncThunk(
  "megacrafters/fetchCart",
  async () => {
    const response = await fetch("https://admin.clejenterprise.com/api/cart", {
      headers: {
        "Content-Type": "application/json",
        "Referrer-Policy": "no-referrer",
      },
    }); //
    const data = await response.json();

    if (data === undefined) {
      return [];
    }

    return data;
  }
);

// Fetch products by category from the API
export const fetchProductsByCategory = createAsyncThunk(
  "megacrafters/fetchProductsByCategory",
  async (categoryId) => {
    const response = await fetch(
      `https://admin.clejenterprise.com/api/products/category/${categoryId}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Referrer-Policy": "no-referrer",
        },
      }
    );
    const data = await response.json();
    if (data === undefined) {
      return [];
    }
    return data; // This will be an array of products filtered by category
  }
);

export const addCart = async (payload) => {
  const response = await fetch("https://admin.clejenterprise.com/api/cart", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Referrer-Policy": "no-referrer",
    },
    body: JSON.stringify(payload),
  });
  const data = await response.json();
  if (data === undefined) {
    return [];
  }
  return data;
};

export const updateCart = async (payload) => {
  const response = await fetch("https://admin.clejenterprise.com/api/cart", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Referrer-Policy": "no-referrer",
    },
    body: JSON.stringify(payload),
  });
  const data = await response.json();
  if (data === undefined) {
    return [];
  }
  return data;
};

export const clearCart = async (payload) => {
  const response = await fetch("https://admin.clejenterprise.com/api/cart", {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Referrer-Policy": "no-referrer",
    },
    credentials: "include", // Include credentials in the request
    body: JSON.stringify(payload), // Replace with your payload
  });
  const data = await response.json();
  if (data === undefined) {
    return [];
  }
  return data; // This will be the response data from the API
};

export const megaSlice = createSlice({
  name: "megacrafters",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const item = state.cart.products.find(
        (item) => item._id === action.payload._id
      );
      if (item) {
        item.quantity += action.payload.quantity;
      } else {
        state.cart.products.push(action.payload);
      }
      addCart({ _id: action.payload._id, quantity: action.payload.quantity });
    },
    increaseQuantity: (state, action) => {
      const item = state.cart.products.find(
        (item) => item._id === action.payload._id
      );
      if (item) {
        item.quantity++;
      }
      updateCart({ _id: action.payload._id, quantity: item.quantity });
    },
    decreaseQuantity: (state, action) => {
      const item = state.cart.products.find(
        (item) => item._id === action.payload._id
      );
      if (item.quantity === 1) {
        item.quantity = 1;
      } else {
        item.quantity--;
      }
      updateCart({ _id: action.payload._id, quantity: item.quantity });
    },
    deleteItem: (state, action) => {
      state.cart.products = state.cart.products.filter(
        (item) => item._id !== action.payload
      );
      clearCart({ _id: action.payload });
    },
    resetCart: (state) => {
      state.cart.products = [];
      clearCart();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userInfo = action.payload;
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchCart.pending, (state) => {
        state.cartStatus = "loading";
      })
      .addCase(fetchCart.fulfilled, (state, action) => {
        state.cartStatus = "succeeded";
        state.cart = action.payload; // Populate cart with data from API
      })
      .addCase(fetchCart.rejected, (state, action) => {
        state.cartStatus = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchCategories.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  deleteItem,
  decreaseQuantity,
  increaseQuantity,
  addToCart,
  resetCart,
} = megaSlice.actions;
export default megaSlice.reducer;
